const appVersion = 'v3.0.7';
const mapboxAccessToken = 'pk.eyJ1IjoiYmFjaW5nZXIiLCJhIjoiY2p3bTc1eDV6MWEyZDN5cWtkYzFhdm02diJ9.BKmZeZ1pIDbq9TRAVpKxPQ';
const mapboxCustomStyle = 'mapbox://styles/bacinger/clx4w7o9g00ay01qs39ztdbgm';
const mapInit = [16.25, 44.5];
let isPickPointActive = false;
let map;

function setProj4Defs() {
  proj4.defs([
    [
        'EPSG:4326',
        '+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees'],
    [
        'EPSG:3765',
        '+title=HTRS96/TM +proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'],
    [
        'EPSG:31275',
        '+title=Gauss–Krüger 5 zona +proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=5500000 +y_0=0 +ellps=bessel +towgs84=550.499,164.116,475.142,5.80967,2.07902,-11.62386,0.99999445824 +units=m +no_defs'],
    [
        'EPSG:31276',
        '+title=Gauss–Krüger 6 zona +proj=tmerc +lat_0=0 +lon_0=18 +k=0.9999 +x_0=6500000 +y_0=0 +ellps=bessel +towgs84=550.499,164.116,475.142,5.80967,2.07902,-11.62386,0.99999445824 +units=m +no_defs'],
    [
        'EPSG:31277',
        '+title=Gauss–Krüger 7 zona +proj=tmerc +lat_0=0 +lon_0=21 +k=0.9999 +x_0=7500000 +y_0=0 +ellps=bessel +towgs84=574.027,170.175,401.545,4.88786,-0.66524,-13.24673,0.99999311067 +units=m +no_defs'
    ]
  ]);
}

function goReset() {
    turnAllPanelsOff();
    document.getElementById('srcCoord1').value = '';
    document.getElementById('srcCoord2').value = '';
    document.getElementById('dstCoord').value = '';
    map.getSource('coordinates').setData({
        'type': 'FeatureCollection',
        'features': []
    });
    document.getElementById('tableBody').innerHTML = '';
    document.getElementById('downloadCSV').classList.add('is-hidden');
    document.getElementById('showTable').classList.add('is-hidden');
    map.setZoom(6);
    map.flyTo({center: mapInit});
    plausible('Click', {props: {action: 'Reset map'}});
}

function turnAllPanelsOff() {
    (document.querySelectorAll('.message') || []).forEach(($article) => {
        $article.classList.add('is-hidden');
    });
}

function navTransformOne() {
    turnAllPanelsOff();
    document.getElementById('panelTrasnform').classList.remove('is-hidden');
}

function navTransformFile() {
    turnAllPanelsOff();
    document.getElementById('panelFile').classList.remove('is-hidden');
}

function navAbout() {
    turnAllPanelsOff();
    document.getElementById('panelAbout').classList.remove('is-hidden');    
}

function toggleTable() {
  document.getElementById('panelOutput').classList.toggle('is-hidden');
}

function CSVToArray(strData, strDelimiter) {
    strDelimiter = (strDelimiter || ',');
    let objPattern = new RegExp(("(\\" + strDelimiter + "|\\r?\\n|\\r|^)" + "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" + "([^\"\\" + strDelimiter + "\\r\\n]*))"),"gi");
    let arrData = [[]];
    let arrMatches = null;
    while (arrMatches = objPattern.exec(strData)) {
        let strMatchedDelimiter = arrMatches[1];
        let strMatchedValue;
        if (arrMatches[2]){
            strMatchedValue = arrMatches[2].replace(new RegExp( "\"\"", "g" ), "\"");
        } 
        else {
            strMatchedValue = arrMatches[3];
        }
        if (strMatchedValue != '') {
            if (strMatchedDelimiter.length && (strMatchedDelimiter !== strDelimiter)) {
                arrData.push([]);
            }
            arrData[arrData.length - 1].push(strMatchedValue);
        }
    }
    return(arrData);
}

function handleFileSelect(evt) {
    document.getElementById('tableBody').innerHTML = '';
    document.getElementById('downloadCSV').classList.add('is-hidden');
    document.getElementById('showTable').classList.add('is-hidden');
    let file = evt.target.files[0]; 
    let reader = new FileReader();
    reader.onload = (function() {
        return function(evt) {
            let parsedFile = [[]];
            const $srcEPSG = document.getElementById('fileInput');
            const $dstEPSG = document.getElementById('fileOutput');
            const srcEPSG = $srcEPSG.value;
            const dstEPSG = $dstEPSG.value;
            const srcEPSGtxt = $srcEPSG.options[$srcEPSG.selectedIndex].text;
            const dstEPSGtxt = $dstEPSG.options[$dstEPSG.selectedIndex].text;
            try {
                let fileContent = evt.target.result;
                if (fileContent.indexOf('?xml') > 0) {
                    let parser = new DOMParser();
                    let xmlDoc = parser.parseFromString(fileContent, 'text/xml');
                    let xmlCoords = xmlDoc.getElementsByTagName('trkpt');
                    for (let i=0; i<xmlCoords.length; i++) {
                        parsedFile[i].push(xmlCoords[i].attributes.lat.nodeValue);
                        parsedFile[i].push(xmlCoords[i].attributes.lon.nodeValue);
                        if (i+1 !== xmlCoords.length) {
                            parsedFile.push( [] );
                        }
                    }
                    plausible('Transformation', {props: {target: 'GPX', conversion: srcEPSGtxt + ' > ' + dstEPSGtxt}});
                }
                else {
                    parsedFile = CSVToArray(fileContent, ',');
                    plausible('Transformation', {props: {target: 'CSV', conversion: srcEPSGtxt + ' > ' + dstEPSGtxt}});
                }
            } 
            catch(err) {
                console.warn(err);
            }
            let transformedFile = [[]];
            let bounds = new mapboxgl.LngLatBounds();
            let obj =  {
                'type': 'FeatureCollection',
                'features': []
            };
            for (let j=0; j<parsedFile.length; j++) {
                let num1 = parsedFile[j][0];
                let num2 = parsedFile[j][1];
                if (IsNumeric(num1) && IsNumeric(num2)) {
                    let dstCoordObj = doTransformation(+num1, +num2, srcEPSG, dstEPSG);
                    if (dstCoordObj !== null) {
                        if (dstEPSG === '4326') {
                            transformedFile[j].push(dstCoordObj.lat.toFixed(6));
                            transformedFile[j].push(dstCoordObj.lng.toFixed(6));
                        }
                        else {
                            transformedFile[j].push(dstCoordObj.lng.toFixed(2));
                            transformedFile[j].push(dstCoordObj.lat.toFixed(2));
                        }
                        if (j+1 !== parsedFile.length) {
                            transformedFile.push( [] );
                        }
                        let feat = {
                                    'type': 'Feature',
                                    'geometry': {
                                        'type': 'Point',
                                        'coordinates': [dstCoordObj.mapLng, dstCoordObj.mapLat]
                                    }
                        };
                        obj.features.push(feat);
                        bounds.extend([dstCoordObj.mapLng, dstCoordObj.mapLat]);
                    }
                }
                else {
                    transformedFile[j].push('input');
                    transformedFile[j].push('error');
                    if (j+1 !== parsedFile.length) {
                        transformedFile.push( [] );
                    }
                }
            }
            if (obj.features.length > 0) {
                map.getSource('coordinates').setData(obj);
                map.fitBounds(bounds);
                let outputList = '<tr><th class="has-text-centered">' + srcEPSGtxt + '</th><th class="has-text-centered">' + dstEPSGtxt + '</th></tr>';
                let outputFile = '';
                for (let k=0; k<parsedFile.length; k++) {
                    outputList += '<tr class="has-text-centered"><td>' + parsedFile[k][0] + ', ' + parsedFile[k][1] + '</td><td>' + transformedFile[k][0] + ', ' + transformedFile[k][1] + '</td></tr>';
                    outputFile += parsedFile[k][0] + ', ' + parsedFile[k][1] + ', ' + transformedFile[k][0] + ', ' + transformedFile[k][1] + '\r\n';
                }
                document.getElementById('tableBody').innerHTML = outputList;
                let encodedUri = 'data:application/octet-stream,' + encodeURIComponent(outputFile);
                let $link = document.getElementById('downloadCSV');
                $link.setAttribute('href', encodedUri);
                $link.setAttribute('onclick', 'plausible("Click", {props: {action: "Download CSV"}});');
                $link.setAttribute('download', 'coordinates.csv');
                $link.classList.remove('is-hidden');
                document.getElementById('showTable').classList.remove('is-hidden');
                document.getElementById('panelOutput').classList.remove('is-hidden');
            }
            else {
              console.warn('Input file is not a valid document');
            }
            document.getElementById('files').value = '';
        };
    })(file);
    reader.readAsText(file);
}

function doTransformation(num1, num2, srcEPSG, dstEPSG) {
    let srcPosition = {};
    let dstCoordObj = {};
    try {
        if (srcEPSG === '4326') {
            srcPosition.lng = num2;
            srcPosition.lat = num1;
        }
        else {
            srcPosition.lng = num1;
            srcPosition.lat = num2;
        }
        let dstCoordArray = proj4(proj4('EPSG:'+srcEPSG),proj4('EPSG:'+dstEPSG),[srcPosition.lng,srcPosition.lat]);
        let mapCoordArray = proj4(proj4('EPSG:'+srcEPSG),proj4('EPSG:4326'),[srcPosition.lng,srcPosition.lat]);
        dstCoordObj.lng = dstCoordArray[0];
        dstCoordObj.lat = dstCoordArray[1];
        dstCoordObj.mapLng = mapCoordArray[0];
        dstCoordObj.mapLat = mapCoordArray[1];
        return dstCoordObj;
    } 
    catch(err) {
        console.warn(err);
    }
}

function goTransform() {
    const $elCoord1 = document.getElementById('srcCoord1');
    const $elCoord2 = document.getElementById('srcCoord2');
    const $dstCoorEl = document.getElementById('dstCoord');
    const $srcEPSG = document.getElementById('csInput');
    const $dstEPSG = document.getElementById('csOutput');
    const srcEPSG = $srcEPSG.value;
    const dstEPSG = $dstEPSG.value;
    const srcEPSGtxt = $srcEPSG.options[$srcEPSG.selectedIndex].text;
    const dstEPSGtxt = $dstEPSG.options[$dstEPSG.selectedIndex].text;
    const num1 = $elCoord1.value;
    const num2 = $elCoord2.value;
    if (IsNumeric(num1) && IsNumeric(num2)) {
        const dstCoordObj = doTransformation(+num1, +num2, srcEPSG, dstEPSG);
        if (dstCoordObj !== null) {
            if (dstEPSG === '4326') {
                $dstCoorEl.value = dstCoordObj.lat.toFixed(6) + ', ' + dstCoordObj.lng.toFixed(6);
            }
            else {
                $dstCoorEl.value = dstCoordObj.lng.toFixed(2) + ', ' + dstCoordObj.lat.toFixed(2);
            }
            map.getSource('coordinates').setData({
                'type': 'FeatureCollection',
                'features': [{
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [dstCoordObj.mapLng, dstCoordObj.mapLat]
                    }
                }]
            });
            map.flyTo({center: [dstCoordObj.mapLng, dstCoordObj.mapLat]});
            plausible('Transformation', {props: {target: 'Point', conversion: srcEPSGtxt + ' > ' + dstEPSGtxt}});
        }
    }
    else {
        console.warn('Input is not a valid number');
    }
}

function goPickPoint() {
    map.getCanvas().style.cursor = 'pointer';
    isPickPointActive = true;
}

function watchForEnter(e) {
    if (e.keyCode === 13) {
        goTransform();
    }
}

function IsNumeric(input) {
    let RE = /^-{0,1}\d*\.{0,1}\d+$/;
    return (RE.test(input));
}

function mapBackgroundChange() {
    const layerId = document.getElementById('mapBackground').value;
    const obj = map.getSource('coordinates').serialize();
    map.off('click');
    map.off('mouseenter');
    map.off('mouseleave');
    if (layerId == 'bubble') {
        map.setStyle(mapboxCustomStyle);
    } 
    else {
        map.setStyle('mapbox://styles/mapbox/' + layerId);
    }
    plausible('Baselayer', {props: {layer: layerId}});
    map.once('styledata', () => {
        mapLoad(obj);
    });
}

function mapLoad(obj) {
    const catURL = document.getElementById('parcelCat').src;
    map.loadImage(catURL, function(error, image) {
        if (error) throw error;
        let feat = []; // Return map features
        if (typeof obj !== 'undefined') {
            if (obj.data.features.length > 0) {
                feat = obj.data.features;
            }
        }
        map.addImage('cat', image);
        map.addSource('coordinates', {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': feat
            }
        });
        map.addLayer({
            'id': 'symbols',
            'type': 'symbol',
            'source': 'coordinates',
            'layout': {
                'icon-image': 'cat',
                'icon-size': 1
            }
        });
    });
    map.on('click', (e) => {
        if (isPickPointActive) {
            document.getElementById('csInput').value = '4326';
            const elCoord1 = document.getElementById('srcCoord1');
            const elCoord2 = document.getElementById('srcCoord2');
            elCoord1.value = e.lngLat.lat.toFixed(6);
            elCoord2.value = e.lngLat.lng.toFixed(6);            
            plausible('Click', {props: {action: 'Pick a point'}});
            map.getCanvas().style.cursor = '';
            isPickPointActive = false;
        }
    });
    map.on('click', 'symbols', (e) => {
        map.flyTo({center: e.features[0].geometry.coordinates});
    });
    map.on('mouseenter', 'symbols', () => {
        map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'symbols', () => {
        map.getCanvas().style.cursor = '';
    }); 
}

window.addEventListener('load', () => {
    // App version
    document.getElementById('appversion').textContent = appVersion;
    // Add custom projections to proj4
    setProj4Defs();
    // Get 'navbar-burger' element and add a click event
    const $navbarBurger = document.getElementById('navbarBurger');
    $navbarBurger.addEventListener('click', () => {
        // Get the target from the 'data-target' attribute
        const target = $navbarBurger.dataset.target;
        const $target = document.getElementById(target);
        // Toggle the 'is-active' class on both the 'navbar-burger' and the 'navbar-menu'
        $navbarBurger.classList.toggle('is-active');
        $target.classList.toggle('is-active');
    });
    // Get all 'delete' icons and attach hide event
    (document.querySelectorAll('.message-header .delete') || []).forEach(($delete) => {
        const $notificationHeader = $delete.parentNode;
        const $container = $notificationHeader.parentNode;
        const $section = $container.parentNode;
        const $notification = $section.parentNode;
        $delete.addEventListener('click', () => {
            $notification.classList.toggle('is-hidden');
        });
    });
    // Resize Table Output and About panel on mobile
    document.getElementById('tableOutput').style.maxHeight = +window.innerHeight - 250 + 'px';
    if (window.innerWidth <= 768) {
        document.getElementById('panelAbout').classList.add('is-small');
    }
    // MapBox stuff
    mapboxgl.accessToken = mapboxAccessToken;
    map = new mapboxgl.Map({
        container: 'map', // container ID
        style: 'mapbox://styles/mapbox/satellite-v9', // style URL
        center: mapInit, // starting position
        zoom: 6 // starting zoom
    });
    const nav = new mapboxgl.NavigationControl({
        showCompass: false
    });
    map.addControl(nav, 'top-right');
    map.touchZoomRotate.disableRotation(); // disable map rotation using touch rotation gesture
    map.dragRotate.disable(); // disable map rotation using right click + drag
    plausible('Baselayer', {props: {layer: 'satellite-v9'}});
    // Add a symbol layer on map load
    map.on('load', function() {
        mapLoad();
    });  
    // Events
    document.getElementById('navHome').addEventListener('click', goReset, false);
    document.getElementById('navTransformOne').addEventListener('click', navTransformOne, false);
    document.getElementById('navTransformFile').addEventListener('click', navTransformFile, false);
    document.getElementById('navAbout').addEventListener('click', navAbout, false);
    document.getElementById('buttonPickPoint').addEventListener('click', goPickPoint, false);
    document.getElementById('buttonTransform').addEventListener('click', goTransform, false);
    document.getElementById('showTable').addEventListener('click', toggleTable, false);
    document.getElementById('srcCoord2').addEventListener('keyup', watchForEnter, false);
    document.getElementById('mapBackground').addEventListener('change', mapBackgroundChange, false);
    if (window.File && window.FileReader && window.FileList ) {
        document.getElementById('files').addEventListener('change', handleFileSelect, false);
    } else {
        document.getElementById('navTransformFile').classList.add('is-static');
    }
});